import React, { useState, useEffect } from 'react';
import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/Layout';

import en from '../locales/en-GB/translation.json';
import cy from '../locales/cy-GB/translation.json';

import SmartFeedList from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedList';

import { useFiltersContext } from '@accrosoft-ltd/vf-careers-site-theme/src/context/FiltersContext';

import { useI18nContext } from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';
import Card from 'react-bootstrap/Card';

import fetchJsonp from 'fetch-jsonp';

export default function HomepagePage(props) {
  const {
    i18nState: { lang },
    t,
  } = useI18nContext();
  const {
    filtersState: { filters },
    onSelectionChange,
  } = useFiltersContext();

  const {
    pageContext: { config, integratorData, contentMetaData, contentDataData },
  } = props;

  const siteConfig = config;

  const apiKey = siteConfig.apiKey || props.smartfeedID;
  const gdprKey = siteConfig.gdprKey || props.apiKey || props.smartfeedID;
  const groupOrIdParam = siteConfig.group ? 'group' : 'id';
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);
  const [vacancyLoading, setVacancyLoading] = useState(true);

  useEffect(() => {
    if (apiKey) {
      let companyDetailsEndpoint = 'GetCompanyDetails';

      if (groupOrIdParam === 'group') {
        companyDetailsEndpoint = 'GetGroupDetails';
      }

      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            '/CareerPage/' +
            companyDetailsEndpoint +
            '?id=' +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
                '/CareerPage/' +
                'GetApplicantGetwayURL' +
                '?companyId' +
                '=' +
                gdprKey,
              {
                timeout: 15000,
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (appgatway) {
                setVacanciesRouteLoading(false);
                if (
                  jsonList &&
                  jsonList.CompanyDetails &&
                  jsonList.CompanyDetails.length > 0
                ) {
                  setSmartfeedCompanyData(jsonList.CompanyDetails[0]);
                } else {
                  setSmartfeedCompanyData(jsonList);
                }
                setAppGatewayURL(appgatway);
              })
              .catch(function (ex) {
                setVacanciesRouteLoading(false);
                setVacanciesRouteError(ex);
                console.error(ex, 'error');
              });
          })
          .catch(function (ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, 'error');
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, 'error');
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError('No API key was provided.');
    }
  }, [apiKey, gdprKey, groupOrIdParam, siteConfig.sfAPIEndpoint]);

  const companyName =
    siteConfig?.CompanyName ||
    props?.smartfeedCompanyData?.CompanyName ||
    'NPTC Group of Colleges';

  const onNavChange = (value) => {
    var temp = {
      target: {
        name: 'postingtype',
        value,
      },
    };
    onSelectionChange(temp);
  };

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={apiKey}
      groupOrIdParam={groupOrIdParam}
      smartfeedCompanyData={smartfeedCompanyData}
      appGatewayURL={appGatewayURL}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      title={`${companyName} ${t('homepage.careers')}`}
      description={`Careers at ${companyName}`}
    >
      {!vacanciesRouteLoading &&
      (siteConfig.siteConfig || smartfeedCompanyData) &&
      apiKey ? (
        <>
          <div className="home">
            <section className="app-content">
              {contentDataData && contentDataData.length > 0 && (
                <div>
                  <div className="app-hero-wrapper app-hero-home app-hero-video">
                    <div className="app-hero-video-wrap">
                      <div className="video_item">
                        <div className="video_position">
                          <video
                            preload="true"
                            muted
                            autoPlay={true}
                            loop
                            playsInline
                            width="100%"
                            height="100%"
                          >
                            <source
                              src="https://cdn1.accropress.com/NPTC/videoplayback.mp4"
                              type="video/mp4"
                            />

                            <img
                              src={contentDataData[0].children[0].value}
                              alt="Your browser does not support the <video> tag"
                            />
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="app-content-content">
                    <div className="content-block-intro">
                      <div className="container">
                        <div className="homepage-opener-section row">
                          <div className="col-xs-12 homepage-opener-section-center">
                            <h1>
                              {lang === 'en-GB'
                                ? t('homepage.title')
                                : 'GYRFAOEDD YNG NGRŴP COLEGAU NPTC'}
                            </h1>
                            <div className="page-content-wysiwyg">
                              {lang === 'en-GB'
                                ? t('homepage.subtitle')
                                : `YDYCH CHI'N BAROD AM YRFA GYFFROUS?`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="content-block-light">
                      <div className="content-block-vacancies">
                        <div className="container">
                          <SmartFeedList
                            siteConfig={siteConfig}
                            apiKey={siteConfig.apiKey || apiKey}
                            group={
                              siteConfig.group
                                ? siteConfig.group
                                : groupOrIdParam === 'id'
                                ? false
                                : groupOrIdParam === 'group'
                                ? true
                                : false
                            }
                            smartfeedCompanyData={
                              siteConfig.smartfeedCompanyData ||
                              smartfeedCompanyData
                            }
                            vacancyLoading={vacancyLoading}
                            setVacancyLoading={setVacancyLoading}
                          />
                        </div>
                      </div>

                      {/* cards */}
                      <div className="container">
                        <div className="row">
                          <div className="col-xs-12 col-12 col-md-4 mb-4">
                            <div>
                              <Card>
                                <Card.Img
                                  className="btn-card-external-img"
                                  variant="top"
                                  src="https://cdn1.accropress.com/NPTC/external/application.jpeg"
                                />
                                <Card.Body style={{ padding: '0px' }}>
                                  <a
                                    className="btn-card-external btn btn-primary"
                                    href={
                                      lang === 'en-GB'
                                        ? 'https://www.nptcgroup.ac.uk/application-process/'
                                        : 'https://www.nptcgroup.ac.uk/cy/y-broses-ymgeisio/'
                                    }
                                    variant="primary"
                                  >
                                    {t('custom-cards.1')}
                                  </a>
                                </Card.Body>
                              </Card>
                            </div>
                          </div>
                          <div className="col-xs-12 col-12 col-md-4 mb-4">
                            <Card>
                              <Card.Img
                                className="btn-card-external-img"
                                variant="top"
                                src="https://cdn1.accropress.com/NPTC/external/faq.jpeg"
                              />
                              <Card.Body style={{ padding: '0px' }}>
                                <a
                                  className="btn-card-external btn btn-primary"
                                  href={
                                    lang === 'en-GB'
                                      ? 'https://www.nptcgroup.ac.uk/faqs/'
                                      : 'https://www.nptcgroup.ac.uk/cy/cwestiynau-cyffredin/'
                                  }
                                  variant="primary"
                                >
                                  {t('custom-cards.2')}
                                </a>
                              </Card.Body>
                            </Card>
                          </div>
                          <div className="col-xs-12 col-12 col-md-4 mb-4">
                            <Card>
                              <Card.Img
                                className="btn-card-external-img"
                                variant="top"
                                src="https://cdn1.accropress.com/NPTC/external/recruitment.jpeg"
                              />
                              <Card.Body style={{ padding: '0px' }}>
                                <a
                                  className="btn-card-external-separate btn btn-primary"
                                  style={{ whiteSpace: 'pre-wrap' }}
                                  href={
                                    lang === 'en-GB'
                                      ? 'https://www.nptcgroup.ac.uk/recruitment-ex-offenders-policy/'
                                      : 'https://www.nptcgroup.ac.uk/cy/polisi-recriwtio-cyn-droseddwyr/'
                                  }
                                  variant="primary"
                                >
                                  {t('custom-cards.3')}
                                </a>
                              </Card.Body>
                            </Card>
                          </div>
                          <div className="col-xs-12 col-12 col-md-4 mb-4">
                            <Card>
                              <Card.Img
                                className="btn-card-external-img"
                                variant="top"
                                src="https://cdn1.accropress.com/NPTC/external/selection.jpeg"
                              />
                              <Card.Body style={{ padding: '0px' }}>
                                <a
                                  className="btn-card-external btn btn-primary"
                                  href={
                                    lang === 'en-GB'
                                      ? 'https://www.nptcgroup.ac.uk/interview-process/'
                                      : 'https://www.nptcgroup.ac.uk/cy/y-broses-ddethol/'
                                  }
                                  variant="primary"
                                >
                                  {t('custom-cards.4')}
                                </a>
                              </Card.Body>
                            </Card>
                          </div>
                          <div className="col-xs-12 col-12 col-md-4 mb-4">
                            <Card>
                              <Card.Img
                                className="btn-card-external-img"
                                variant="top"
                                src="https://cdn1.accropress.com/NPTC/external/staff.jpeg"
                              />
                              <Card.Body style={{ padding: '0px' }}>
                                <a
                                  className="btn-card-external btn btn-primary"
                                  href={
                                    lang === 'en-GB'
                                      ? 'https://www.nptcgroup.ac.uk/staff-benefits/'
                                      : 'https://www.nptcgroup.ac.uk/cy/buddion-staff/'
                                  }
                                  variant="primary"
                                >
                                  {t('custom-cards.5')}
                                </a>
                              </Card.Body>
                            </Card>
                          </div>
                        </div>
                      </div>

                      <div className="_logos_1kqab4 _logos_1kqab4_footer_2">
                        <div className="container">
                          <div className="row">
                            <div className="footer-logo-container col-xs-12 col-12 col-md-3 col-3-m">
                              <div className="footer-logo footer-logo-apprenticeships">
                                <img src={t('custom-logos.1')} />
                              </div>
                            </div>
                            <div className="footer-logo-container col-xs-12 col-12 col-md-2 col-2-m">
                              <div className="footer-logo footer-logo-mindful-employer">
                                {' '}
                                <img src={t('custom-logos.2')} />
                              </div>
                            </div>
                            <div className="footer-logo-container col-xs-12 col-12 col-md-3 col-3-m">
                              <div className="footer-logo footer-logo-disability-employer">
                                {' '}
                                <img src={t('custom-logos.3')} />
                              </div>
                            </div>
                            <div className="footer-logo-container col-xs-12 col-12 col-md-2 col-2-m">
                              <div className="footer-logo footer-logo-mindful-employer">
                                {' '}
                                <img
                                  style={{
                                    maxHeight: '100%',
                                    width: 'auto',
                                  }}
                                  src={t('custom-logos.5')}
                                />
                              </div>
                            </div>
                            <div className="footer-logo-container col-xs-12 col-12 col-md-2 col-2-m">
                              <div className="footer-logo footer-logo-armed-forces">
                                {' '}
                                <img src={t('custom-logos.4')} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        </>
      ) : (
        <div className="notfound">
          <section className="app-content">
            <div className="app-content-content">
              <div className="container">
                <div className="loading-panel">
                  <div className="loading-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span className="loading-text">
                    {t('messages.please-wait-content')}
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}
