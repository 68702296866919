import React from 'react';
import { useStatePersist } from 'use-state-persist';

import { useI18nContext } from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';

const SmartFeedJobCard = ({
  job,
  apiKey,
  viewBySelection,
  siteConfig,
  useGroupVacsLink,
}) => {
  const {
    i18nState: { lang },
    t,
  } = useI18nContext();
  const [savedJobs, setSavedJobs] = useStatePersist('@savedJobs_' + apiKey, []);

  function unSaveJob(id) {
    const newSavedJobsArray = savedJobs.filter((e) => e !== id.toString());
    setSavedJobs(newSavedJobsArray);
  }

  function saveJob(id) {
    setSavedJobs((savedJobsOld) => [...savedJobsOld, id]);
  }

  const calculateSalary = function calculateSalary(
    Currency,
    SalaryFrom,
    SalaryTo,
    SalaryType
  ) {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    let NewSalary;
    let NewSalaryFrom;
    let NewSalaryTo;

    let currencyFormatted = Currency;

    if (Currency === '&pound;') {
      currencyFormatted = '£';
    }

    if (SalaryTo === 0) {
      NewSalary = SalaryType;
      if (NewSalary === 'Competitive' && lang === 'cy-GB') {
        NewSalary = 'Cystadleuol';
      } else if (NewSalary === 'Negotiable' && lang === 'cy-GB') {
        NewSalary = 'Agored i drafodaeth';
      } else if (NewSalary === 'Voluntary' && lang === 'cy-GB') {
        NewSalary = 'Gwirfoddol';
      } else if (NewSalary === 'Unpaid' && lang === 'cy-GB') {
        NewSalary = 'Di-dâl';
      } else if (NewSalary === 'National Minimum Wage' && lang === 'cy-GB') {
        NewSalary = 'Isafswm Cyflog Cenedlaethol';
      }
    } else if (SalaryFrom === SalaryTo) {
      if (SalaryFrom >= 1000) {
        // Remove decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        // Add two decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      NewSalary = NewSalaryFrom;
    } else {
      if (SalaryFrom >= 1000) {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      if (SalaryTo >= 1000) {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed());
      } else {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2));
      }

      NewSalary = NewSalaryFrom + ' - ' + NewSalaryTo;
    }
    return NewSalary;
  };

  const FormatDateTime = function FormatDateTime(JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)));
    var dateFormatOptions = new Object({});
    // dateFormatOptions.weekday = 'long';
    dateFormatOptions.year = 'numeric';
    dateFormatOptions.month = '2-digit';
    dateFormatOptions.day = '2-digit';
    return expDate.toLocaleDateString(undefined, dateFormatOptions);
  };

  const getSummary = function getSummary(summary, description) {
    if (summary) {
      return summary;
    } else {
      let alteredDescription = description.replace(/<[^>]*>?/gm, '');
      let tempSummary = alteredDescription.substring(0, 397);
      if (tempSummary.length === 397) {
        tempSummary += '...';
      }
      return tempSummary;
    }
  };

  const slugify = function slugify(text) {
    return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters

      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-'); // Replace multiple - with single -
  };

  const getImageFromRegionCards = function getImageFromRegionCards(region) {
    let jobCardRegionNoSpaces = region.replace(/[, ]+/g, '-');

    return jobCardRegionNoSpaces;
  };

  const getImageFromRegionCardsNoSlashes =
    function getImageFromRegionCardsNoSlashes(region) {
      let noSpaces = region.replace(/[, ]+/g, '');
      let noSlashes = noSpaces.replaceAll('/', '');
      return noSlashes;
    };

  return (
    <div
      key={job.AdvertReference}
      className="col-12 col-xs-12 vacancy-card-wrap"
    >
      <div className="vacancy vacancy-card vacancy-card-v2">
        <div className="row">
          <div className="col-12 col-md-8 col-xs-12 col-md-8 vacancy-main-details p-0">
            <div className="vacancy-main-details-inner">
              <h3 className="job-title">{job.JobTitle}</h3>

              <div className="job-info-container row">
                <div className="vacancy-details-wrap col-12 col-md-6 pl-0">
                  <strong>{t('vacancies.job-card.region')}: </strong>
                  <span>{job.Region}</span>
                </div>
                {/* <div className="vacancy-details-wrap col-12 col-md-6">
                <strong>
                  {t(
                    'vacancies.job-card.location'
                  )}
                  :{' '}
                </strong>
                <span>{job.Location}</span>
              </div> */}
                <div className="vacancy-details-wrap col-12 col-md-6 pr-0">
                  <strong>{t('vacancies.job-card.category')}: </strong>
                  <span>{job.JobCategory}</span>
                </div>
                <div className="vacancy-details-wrap col-12 col-md-6 pl-0">
                  <strong>{t('vacancies.job-card.salary')}: </strong>
                  <span>
                    {calculateSalary(
                      job.Currency,
                      job.SalaryFrom,
                      job.SalaryTo,
                      job.SalaryType,
                      job.SalaryPeriod
                    )}
                  </span>
                </div>
                <div className="vacancy-details-wrap col-12 col-md-6 pr-0">
                  <strong>{t('vacancies.job-card.expires')}: </strong>
                  <span>{FormatDateTime(job.ExpiryDate)}</span>
                </div>
              </div>

              <div className="job-summary">
                {getSummary(job.Summary, job.Description)}
              </div>
              <div className="extra content">
                <div className="ui two buttons">
                  <a
                    className="btn btn-primary"
                    href={
                      '/vacancies/vacancy/' +
                      new Buffer(job.Id).toString('base64') +
                      '/' +
                      slugify(job.JobTitle) +
                      '?company=' +
                      encodeURIComponent(getImageFromRegionCards(job.Region))
                    }
                  >
                    {t('vacancies.job-card.cta')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 col-xs-12 col-md-4 vacancy-secondary-details">
            <div className="vacancy-brand-image-block">
              <img
                src={
                  'https://cdn1.accropress.com/NPTC/cards/' +
                  getImageFromRegionCardsNoSlashes(job.Region) +
                  '.png'
                }
                alt={getImageFromRegionCardsNoSlashes(job.Region)}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src =
                    'https://cdn1.accropress.com/NPTC/cards/NPTC-Default-Logo.png';
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartFeedJobCard;
