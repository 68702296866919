import React, { useState, useEffect } from 'react';
import { useStatePersist } from 'use-state-persist';
import 'url-search-params-polyfill';

import fetchJsonp from 'fetch-jsonp';
import SmartFeedJobCard from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedJobCard';
import SmartFeedNavigation from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedNavigation';
import SmartFeedJobAlerts from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedJobAlerts';
import SmartFeedFilters from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedFilters';

import { useFiltersContext } from '@accrosoft-ltd/vf-careers-site-theme/src/context/FiltersContext';
import { useI18nContext } from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';

import 'reactjs-popup/dist/index.css';

require('es6-promise').polyfill();

let prevCount = 0;
let currentCount = 0;
let keywordTime = null;

const setVacancyLoadingBackup = function setVacancyLoadingBackup(
  loading,
  vacancyLoading
) {
  if (loading) {
    return true;
  }

  return false;
};

const SmartFeedList = (
  {
    siteConfig,
    apiKey = '',
    group = false,
    smartfeedCompanyData = {},
    useGroupVacsLink,
    vacancyLoading = true,
    setVacancyLoading = setVacancyLoadingBackup,
  },
  props
) => {
  const id = 1;
  const [vacancy, setVacancy] = useState([]);
  const [vacancyTitle, setVacancyTitle] = useState('Loading vacancies...');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);

  const [language, setLanguage] = useState(
    JSON.parse(localStorage.getItem('is-language')) || false
  );

  const {
    i18nState: { lang },
    t,
    changeLang,
  } = useI18nContext();

  // Filter States
  const {
    filtersState: { keywords, filters },
    loading,
    setLoading,
    resetFilters,
    onSelectionChange,
  } = useFiltersContext();

  const [sortBySelection, setSortBySelection] = useStatePersist(
    '@sortBy',
    'postingdate'
  );
  const [sortByDirSelection, setSortByDirSelection] = useStatePersist(
    '@sortDir',
    'desc'
  );

  const [viewBySelection, setViewBySelection] = useStatePersist(
    '@sortView',
    'grid'
  );

  const [vacsCount, setVacsCount] = useState(0);
  const [loadMoreText, setLoadMoreText] = useState(false);

  let groupOrIdParam = 'id';

  if (group === true) {
    groupOrIdParam = 'group';
  }

  useEffect(() => {
    clearTimeout(keywordTime);

    keywordTime = setTimeout(() => {
      fetchVacancies();
    }, 500);
  }, [keywords]);

  useEffect(() => {
    fetchVacancies();
  }, [filters, lang]);

  const fetchVacancies = async (name, loadMore) => {
    setLoadMoreText('Loading.....');

    if (!loadMore) {
      prevCount = 0;
      currentCount = 0;
      setLoading(true);
    }

    if (name === 'reset') {
      resetFilters();
    }

    function getAdvertData(jobs, prevCount, currentCount, query) {
      return Promise.all(
        jobs.slice(prevCount, currentCount).map(async (id) => {
          return fetchJsonp(
            siteConfig.sfAPIEndpoint +
              '/CareerPage/GetItem?id=' +
              id +
              '&' +
              query,
            {
              timeout: 15000,
            }
          ).then(function (response) {
            return response.json();
          });
        })
      );
    }

    try {
      const postingTypeValue =
        name === 'reset'
          ? 'careers'
          : filters.postingtype === 'Alerts'
          ? 'careers'
          : filters.postingtype || 'careers';

      const query = name === 'reset' ? '' : '&' + buildQuery();

      fetchJsonp(
        siteConfig.sfAPIEndpoint +
          '/CareerPage/GetResultList?' +
          groupOrIdParam +
          '=' +
          apiKey +
          '&languageIdentifier=' +
          lang +
          '&sortby=' +
          sortBySelection +
          '&sortdir=' +
          sortByDirSelection +
          '&postingtype=' +
          postingTypeValue +
          '&keywords=' +
          keywords +
          query,
        {
          timeout: 15000,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(async function (jsonList) {
          prevCount = currentCount;
          currentCount = currentCount + 12;

          const query =
            'customFieldList=careers_x0020_page_x0020_-_x0020_job_x0020_type|careers_x0020_page_x0020_-_x0020_job_x0020_time';
          await getAdvertData(jsonList, prevCount, currentCount, query).then(
            function (data) {
              if (loadMore) {
                setVacancy(vacancy.concat(data));
              } else {
                setVacancy(data);
              }

              if (jsonList.length === 0) {
                setVacancyTitle(t('messages.couldnt-find-vacancies'));
              } else {
                let count = currentCount;
                if (currentCount > jsonList.length) count = jsonList.length;
                setVacancyTitle(
                  t('vacancies.showing-vacancies', {
                    vars: { count, vacancies: jsonList.length },
                  })
                );
              }

              setInitialLoading(false);

              setLoading(false);
              setVacancyLoading(false);

              setLoadMoreText(t('vacancies.load-more'));
              setVacsCount(jsonList.length);
            }
          );
        });
    } catch (error) {
      setLoading(false);
      setVacancyLoading(false);
      console.error(error.message, 'error');
    }
  };

  const loadMore = () => {
    fetchVacancies(undefined, true);
  };

  const resetVacs = () => {
    fetchVacancies('reset', false);
  };

  const searchVacs = function searchVacs() {
    fetchVacancies('search', false);
  };

  useEffect(() => {
    if (!isInitialLoad) {
      searchVacs();
    }
  }, [sortBySelection, sortByDirSelection]);

  const buildQuery = function buildQuery() {
    let query = [];

    for (let key in filters) {
      if (filters.hasOwnProperty(key)) {
        query.push(
          encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
        );
      }
    }

    return query.join('&');
  };

  useEffect(() => {
    if (isInitialLoad) {
      if (URLSearchParams) {
        var urlParams = new URLSearchParams(window.location.search);
        var entries = urlParams.entries();
      }
      let temp = {};
      for (let pair of entries) {
        temp[pair[0]] = pair[1];
      }

      const timeOut = setTimeout(() => {
        fetchVacancies();

        setIsInitialLoad(false);
      }, 50);
      return () => clearTimeout(timeOut);
    }
  }, [isInitialLoad]);

  const onNavChange = (value) => {
    var temp = {
      target: {
        name: 'postingtype',
        value,
      },
    };
    onSelectionChange(temp);
  };

  return !vacancyLoading &&
    smartfeedCompanyData &&
    smartfeedCompanyData.CompanyName ? (
    <section className="main-vacs-wrap">
      <div className="VF_Nav_Wrap mb-3">
        <SmartFeedNavigation
          active={filters?.postingtype || 'Careers'}
          onNavChange={onNavChange}
        />
      </div>
      {!vacancyLoading &&
        (loading ? (
          t('messages.loading-vacancies')
        ) : (
          <>
            {filters?.postingtype !== 'Alerts' ? (
              <>
                {siteConfig.showAdvancedFilters && (
                  <SmartFeedFilters
                    siteConfig={siteConfig}
                    apiKey={siteConfig.apiKey || props.apiKey}
                    group={false}
                    groupOrIdParam={'id'}
                  />
                )}
                {vacancy &&
                vacancy !== [] &&
                vacancy !== '[]' &&
                !initialLoading ? (
                  <>
                    {vacancy && vacancy.length > 0 ? (
                      vacancy.map((job, i) => (
                        <SmartFeedJobCard
                          job={job}
                          key={i}
                          viewBySelection={viewBySelection}
                          apiKey={apiKey}
                          siteConfig={siteConfig}
                          useGroupVacsLink={useGroupVacsLink}
                        />
                      ))
                    ) : (
                      <div className="job-card card job-card-no-results">
                        <div className="job-card-details">
                          <div className="no-results">
                            {t('messages.no-results')}
                          </div>
                          {/* <button
                            id="reset_vacs"
                            className="nav-action-sm"
                            onClick={() => resetVacs()}
                          >
                            {t('vacancies.reset')}
                          </button> */}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="no-vacancies">
                    {t('messages.loading-vacancies')}
                  </div>
                )}

                <div className="load-more-wrap col-12">
                  {currentCount < vacsCount && (
                    <a
                      className="nav-action-sm"
                      role="button"
                      onClick={() => loadMore()}
                    >
                      {loadMoreText}
                    </a>
                  )}
                </div>
              </>
            ) : (
              <SmartFeedJobAlerts
                siteConfig={siteConfig}
                setVacancyLoading={false}
                apiKey={siteConfig.apiKey}
                group={siteConfig.group || false}
                gdprKey={siteConfig.gdprKey || siteConfig.apiKey}
                JobAlertCompanySettings={siteConfig.JobAlertCompanySettings}
                CompanyGroupName={siteConfig.companyName}
                OptInLink={
                  siteConfig.companyCareersSite
                    ? `${siteConfig.careersSiteDomain}/job-alerts/optin/?OptInID=`
                    : siteConfig.OptInLink ||
                      `${siteConfig.careersSiteDomain}/job-alerts/${siteConfig.apiKey}/optin/?OptInID=`
                }
                UnsubscribeLink={
                  siteConfig.companyCareersSite
                    ? `${siteConfig.careersSiteDomain}/job-alerts/unsubscribe/?UnsubscribeID=`
                    : siteConfig.UnsubscribeLink ||
                      `${siteConfig.careersSiteDomain}/job-alerts/${siteConfig.apiKey}/unsubscribe/?UnsubscribeID=`
                }
                VacancyPageBaseURL={
                  siteConfig.companyCareersSite
                    ? `${siteConfig.careersSiteDomain}/vacancies/vacancy/`
                    : siteConfig.VacancyPageBaseURL ||
                      `${siteConfig.careersSiteDomain}/vacancies/${siteConfig.apiKey}/vacancy/`
                }
                ReturnToSearchURL={
                  siteConfig.companyCareersSite
                    ? `${siteConfig.careersSiteDomain}/vacancies/`
                    : siteConfig.ReturnToSearchURL ||
                      `${siteConfig.careersSiteDomain}/vacancies/${siteConfig.apiKey}/`
                }
                location={siteConfig.showLocationJA}
                region={siteConfig.showRegionJA}
                category={siteConfig.showCategoryJA}
                industry={siteConfig.showIndustryJA}
                jobType={siteConfig.showJobTypeJA}
                jobTime={siteConfig.showJobTimeJA}
              />
            )}
          </>
        ))}
    </section>
  ) : (
    <div className="page-loading-block">
      <div className="loading-panel">
        <div className="loading-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <span className="loading-text">
          {t('messages.please-wait-while-load')}
        </span>
      </div>
    </div>
  );
};

export default SmartFeedList;
