import React, { useState } from 'react';

import { useI18nContext } from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';

const SmartFeedNavigation = ({ active, onNavChange }) => {
  const { t } = useI18nContext();

  return (
    <div className="row VF_Nav_Items">
      <button
        id="VF_Nav_Item_Current"
        className={`col-12 col-md-4 VF_Nav_Item VF_Nav_Item_Current ${
          active === 'Careers' && 'VF_Nav_Item_Active'
        }`}
        onClick={() => {
          onNavChange('Careers');
        }}
      >
        {t('vacancies.navigation.current-vacancies')}
      </button>
      <button
        id="VF_Nav_Item_Register"
        className={`col-12 col-md-4 VF_Nav_Item VF_Nav_Item_Register ${
          active === 'Register' && 'VF_Nav_Item_Active'
        }`}
        onClick={() => {
          onNavChange('Register');
        }}
      >
        {t('vacancies.navigation.register-your-interest')}
      </button>
      <button
        id="VF_Nav_Item_Alerts"
        className={`col-12 col-md-4 VF_Nav_Item VF_Nav_Item_Alerts ${
          active === 'Alerts' && 'VF_Nav_Item_Active'
        }`}
        onClick={() => {
          onNavChange('Alerts');
        }}
      >
        {t('vacancies.navigation.subscribe-to-job-alerts')}
      </button>
    </div>
  );
};

export default SmartFeedNavigation;
